import Vue from 'vue'
import store from '@/store/index.js'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/history',
    name: 'History',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/History')
  },
  {
    path: '/vote',
    name: 'Vote',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Vote')
  },
  {
    path: '/pay-success',
    name: 'PaySuccess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Pay')
  },
  {
    path: '/pay-error',
    name: 'PayError',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Pay/error.vue')
  },
  {
    path: '/pay-loading',
    name: 'PayLoading',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Pay/loading.vue')
  },
  {
    path: '/banner',
    name: 'Banner',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Banner/index.vue')
  },
  {
    path: '/banner/books',
    name: 'NFTBooks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Banner/books.vue')
  },
  {
    path: '/banner/buy',
    name: 'BuyNFT',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Banner/buy.vue')
  },
  {
    path: '/banner/create',
    name: 'CreateNFT',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Banner/create.vue')
  },
  {
    path: '/banner/make',
    name: 'Make',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Banner/make.vue')
  },
  {
    path: '/banner/withdraw',
    name: 'Withdraw',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Banner/withdraw.vue')
  },
  {
    path: '/protocol/privacy-policy',
    name: 'PrivacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/protocol/index.vue')
  },
  {
    path: '/protocol/user-agreement',
    name: 'UserAgreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/protocol/agreement.vue')
  },
  {
    path: '/protocol/logoff-protocol',
    name: 'LogoffProtocol',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/protocol/logoff.vue')
  },
  {
    path: '/download',
    name: 'Download',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Download/index.vue')
  },
  {
    path: '/site',
    name: 'Site',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Site/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 判断是否是 Android || IOS
  const ua = navigator.userAgent.toLowerCase()
  const isIOS = /iphone|ipad|ipod/.test(ua)
  const isAndroid = /android/.test(ua)

  if (isIOS) {
    store.commit('SET_DEVICETYPE', 'ios')
  } else if (isAndroid) {
    store.commit('SET_DEVICETYPE', 'android')
  }

  // 判断语种
  const url = new URL(window.location.href)
  const lang = url.searchParams.get('language') || 'en'
  store.commit('SET_LANG', lang)

  // 判断主题
  const dark_mode = url.searchParams.get('dark-mode') || 'normal'
  let theme = 0
  if (dark_mode === 'dark') {
    theme = 1
  }
  store.commit('SET_THEME', Number(theme) + 1 + '')

  // 判断顶部距离
  const notchHeight = url.searchParams.get('notch-height') || 0
  store.commit('SET_NOTCHHEIGHT', notchHeight)

  next()
})

export default router
