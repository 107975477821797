import Vue from 'vue'
import successPng from '@/assets/img/com/success.png'
import errorPng from '@/assets/img/com/error.png'
Vue.mixin({
  methods: {
    /*
    *desc:'提示框'
    *@params {text :STring} 提示文字
    */
    // notify(text, theme = 1, state = 'success', timeOut = 3000) {
    notify(options) {
      const { text, theme = 1, state = 'success', timeOut = 5000 } = options
      const body = document.querySelector('body')
      const mask = document.createElement('div')
      mask.classList.add('mask')
      const html = `
        <div class="tips tips${theme}">
          <img src="${state === 'success' ? successPng : errorPng}"  alt="">
          <div class="tips-text">${text}</div>
        </div>`
      mask.innerHTML = html
      // 将div元素添加到body中
      body.appendChild(mask)
      return new Promise(resolve => {
        // 设置timeOut秒后的定时器
        setTimeout(() => {
          body.removeChild(mask)
          resolve()
        }, timeOut)
      })
    },
    returnBack() {
      if (this.$store.state.deviceType === 'android') {
        // eslint-disable-next-line no-undef
        return decoObject.onBack()
      } else if (this.$store.state.deviceType === 'ios') {
        return window.webkit.messageHandlers.onBack.postMessage(JSON.stringify({}))
      }
    }

  }
})
