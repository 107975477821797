import Vue from 'vue'
import Vuex from 'vuex'
import vuei8n from '@/vuei8n/index.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    deviceType: '',
    lang: 'en',
    theme: '1',
    notchHeight: 0
  },
  getters: {},
  mutations: {
    SET_DEVICETYPE: (state, data) => {
      state.deviceType = data
    },
    SET_LANG: (state, data) => {
      state.lang = data
      vuei8n.locale = data
    },
    SET_THEME: (state, data) => {
      state.theme = data
    },
    SET_NOTCHHEIGHT: (state, data) => {
      state.notchHeight = data
    }
  },
  actions: {},
  modules: {}
})

export default store
