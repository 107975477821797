import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './vuei8n/index'

Vue.config.productionTip = false

import { Loading, Overlay, Swipe, SwipeItem } from 'vant'

Vue.use(Loading)
Vue.use(Overlay)
Vue.use(Swipe)
Vue.use(SwipeItem)

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

// 混入
import '@/utils/mixin.js'

new Vue({
  router,
  i18n,
  store,
  // VueI18n,
  render: (h) => h(App)
}).$mount('#app')
